import React from "react";
import { graphql } from "gatsby";
import Link from "gatsby-link";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Container from "components/ui/Container";
import TitleSection from "components/ui/TitleSection";
import FormatHtml from "components/utils/FormatHtml";

import * as Styled from "./styles";

const InteractionPost = ({ data, pageContext }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  /*  const post = data.allMarkdownRemark;
    const { previous, next } = pageContext; */

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Container section>
        <TitleSection subtitle={frontmatter.title}></TitleSection>
        <FormatHtml content={html}></FormatHtml>
        <Styled.Links>
          <span>
            <Link to="/work" rel="next">
              ← Work Samples
            </Link>
          </span>
          {/*           <span>
            {next && (
              <Link to="/blog" rel="next">
                Work Samples →
              </Link>
            )}
          </span> */}
        </Styled.Links>
      </Container>
    </Layout>
  );
};

export default InteractionPost;

export const query = graphql`
  query InteractionPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;
